import React from "react";
import _1 from './img/portafolios/1.jpg'
import _2 from './img/portafolios/2.jpg'
import _3 from './img/portafolios/3.jpg'
import _4 from './img/portafolios/4.jpg'
import _5 from './img/portafolios/5.jpg'
import _6 from './img/portafolios/6.jpg'
import _7 from './img/portafolios/7.jpg'
import _8 from './img/portafolios/8.jpg'
import _9 from './img/portafolios/9.jpg'
import _10 from './img/portafolios/10.jpg'
import _11 from './img/portafolios/11.jpg'
import _12 from './img/portafolios/12.jpg'
import _13 from './img/portafolios/13.jpg'
import _14 from './img/portafolios/14.jpg'
import _15 from './img/portafolios/15.jpg'
import _16 from './img/portafolios/16.jpg'
import _17 from './img/portafolios/17.jpg'
import _18 from './img/portafolios/18.jpg'
import _19 from './img/portafolios/19.jpg'
import _20 from './img/portafolios/20.jpg'
import _21 from './img/portafolios/21.jpg'
import _22 from './img/portafolios/22.jpg'
import _23 from './img/portafolios/23.jpg'
import _24 from './img/portafolios/24.jpg'
import _25 from './img/portafolios/25.jpg'
import _26 from './img/portafolios/26.jpg'
import _27 from './img/portafolios/27.jpg'
import _28 from './img/portafolios/28.jpg'
import _29 from './img/portafolios/29.jpg'
import _30 from './img/portafolios/30.jpg'
import _31 from './img/portafolios/31.jpg'
import _32 from './img/portafolios/32.jpg'
import _33 from './img/portafolios/33.jpg'
import _34 from './img/portafolios/34.jpg'
import _35 from './img/portafolios/35.jpg'
import _36 from './img/portafolios/36.jpg'
import _37 from './img/portafolios/37.jpg'

export const portfolio = [ _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, 
  _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37 ]